import { useLockBodyScroll } from "@uidotdev/usehooks";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { CSSProperties, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import CloseIcon from "~/assets/svg/Close";

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};
const containerVariant = {
  initial: { top: "-50%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-50%" },
};

interface ModalProps {
  onClose: () => void;
  children: ReactNode;
  isOpen: boolean;
  classNames?: {
    mask?: string;
    dialog?: string;
    content?: string;
  };
  styles?: {
    mask?: CSSProperties;
    dialog?: CSSProperties;
  };
}

const Modal = ({
  onClose,
  children,
  isOpen,
  classNames = { mask: "", dialog: "", content: "" },
  styles = { mask: {}, dialog: {} },
}: ModalProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial="initial"
            animate="isOpen"
            exit="exit"
            variants={modalVariant}
            style={styles.mask}
            className={cx(
              "backdrop-blur-sm fixed inset-0 w-full h-full bg-base-300/30 z-[60]",
              {
                [classNames.mask]: classNames.mask,
              },
            )}
          />
          <motion.div
            initial="initial"
            animate="isOpen"
            exit="exit"
            variants={containerVariant}
            style={styles.dialog}
            className={cx(
              "fixed left-1/2 -translate-x-1/2 margin-auto top-1/2 -translate-y-1/2 w-full z-[70]",
              {
                [classNames.dialog]: classNames.dialog,
              },
            )}
          >
            <div
              className={cx(
                "fixed z-10 h-12 w-full justify-end backdrop-blur-sm bg-base-100 mt-[calc(env(safe-area-inset-top)+1rem)] md:flex rounded-t-2xl",
              )}
            >
              <CloseIcon
                onClick={onClose}
                className="m-5 h-6 w-6 cursor-pointer text-neutral-500 hover:text-neutral-400"
                fill="currentColor"
                fillRule="evenodd"
              />
            </div>
            <section className="relative w-full h-full flex flex-col rounded-2xl py-12">
              <div
                className={twMerge([
                  "overflow-y-auto no-scrollbar h-full max-h-[90vh] rounded-2xl",
                  classNames.content,
                ])}
              >
                {children}
              </div>
            </section>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Modal;
